<template>

  <div>

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row cols="12">  <!-- Search -->
          <b-col
            cols="6"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search Name..."
              />

            </div>
          </b-col>
          <b-col
            cols="6"
            md="8"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              class="btn-icon  text-right d-flex justify-content-end"
              @click="$router.push('/discount/plan/Add')"
            >
              <feather-icon
                icon="PlusIcon"
                svg-classes="h-4 w-4"
              />

             Add Discount Plan
            </b-button>
            <!-- <b-button
                class="d-flex justify-content-end"
                variant="primary"
                @click="$router.push('/discount/plan/Add')"
              >
                <span class="text-nowrap">Add Discount Plan</span>
              </b-button> -->
          </b-col>
        </b-row>

      </div>

      <b-table-simple
        :per-page="perPage"
        hover
        striped
        bordered
        responsive
        class="rounded-bottom mb-0"
      >
        <b-thead head-variant="light">
          <b-tr>
            <b-th>Commission Slab Name</b-th>
            <b-th>Plan</b-th>
            <b-th>Slab Type</b-th>
            <b-th>Status</b-th>
            <b-th>Commission</b-th>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-show="showLoading"
            class="b-table-busy-slot"
          >
            <b-td colspan="11">
              <div class="text-center text-primary my-2">
                <b-spinner class="align-middle" />
                <strong>Loading...</strong>
              </div>
            </b-td>
          </b-tr>
          <template v-if="list.length>0 && !showLoading">
            <b-tr
              v-for="(tr, indextr) in list"
              :key="indextr"
            >
              <b-td>
                {{ tr.slab_name }}
              </b-td>
              <b-td>{{ tr.plan.plan_name }}</b-td>
              <b-td>
                <p v-if="tr.loginid==activeUserInfo.loginid">
                  Custom
                </p>
                <p v-else>
                  Default
                </p>
              </b-td>
              <b-td>
                <toggle-button
                  :value="(tr.status==1)?true:false"
                  :sync="true"
                  :labels="{checked: 'Active', unchecked: 'Inactive'}"
                  :width="70"
                  @change="openConfirm(tr.id,tr.status,tr.index)"
                />
              </b-td>
              <b-td>
                <feather-icon
                  icon="EditIcon"
                  svg-classes="w-5 h-5 hover:text-primary stroke-current"
                  @click.stop="editData(tr.id)"
                />
              </b-td>
            </b-tr>
          </template>
          <template v-else-if="!showLoading">
            <b-tr rowspan="10">
              <b-td
                colspan="11"
                class="text-center"
              >
                No data avilable
              </b-td>
            </b-tr>
          </template>
        </b-tbody>
      </b-table-simple>
      <b-card-body>
        <b-pagination
          v-if="totalRows>0"
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BButton, BBadge,
  BPagination,
  BFormInput,
  BFormCheckbox,
  VBTooltip,
  BSpinner,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BTableSimple,
  BCardBody,
} from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import SecureLS from 'secure-ls'
import UserService from '../../services/user.service'
import JwtService from '../../common/jwt.service'
import 'animate.css'

export default {
  components: {
    BTableSimple,
    BCard,
    BFormInput,
    BRow,
    BCol,
    BButton,
    BBadge,
    BPagination,
    BCardBody,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    VBTooltip,
    BSpinner,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      activePrompt: false,
      perPage: 10,
      totalRows: 1,
      currentPage: 1,
      isMounted: false,
      slab_name: '',
      selected: [],
      plan_list: [],
      showLoading: false,
      discountPlan: [],
      searchQuery: '',

    }
  },
  computed: {
    list() {
      let start = 0
      let end = 0
      if (this.currentPage == 1) {
        start = 0,
        end = start + this.perPage
      } else {
        start = this.currentPage * this.perPage - 10,
        end = this.currentPage * this.perPage
      }
      if (this.searchQuery) {
        const plan = this.plan_list.filter(item => {
          const search = this.searchQuery.toLowerCase().split(' ').every(v => item.slab_name.toLowerCase().includes(v))
          return search
        })
        this.totalRows = plan.length
        return plan.slice(start, end)
      }
      return this.plan_list.slice(start, end)
    },
    activeUserInfo() {
      const user = JwtService.getUser()
      return user
    },
  },
  mounted() {
    this.isMounted = true
    this.getDiscountPlanList()
  },
  methods: {
    openConfirm(id, status, key) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to change status?',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        showClass: {
          popup: 'animate__animated animate__flipInX',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.changeStatus(id, status, key)
        }
      })
    },
    editData(id) {
      const ls = new SecureLS({ encodingType: 'aes', isCompression: false })
      ls.set('edit_plan_id', id)
      this.$router.push('/discount/plan/edit')
    },
    changeStatus(id, status, key) {
      this.plan_list[key].status = !status
      UserService.changeDiscountPlanStatus({ id, status: !status }).then(
        response => {
          this.$toast({
            component: ToastificationContent,

            props: {
              title: 'Status Change',
              icon: 'InfoIcon',
              text: response.message,
              variant: 'success',
            },
          }, {
            position: 'top-center',
          })
        },
        error => {},
      )
    },
    getDiscountPlanList(data) {
      this.showLoading = true
      UserService.getDiscountPlanList(data).then(
        response => {
          this.plan_list = response.discount_plans
          this.totalRows = response.discount_plans.length
          this.showLoading = false
        },
        error => {
        },
      )
    },
    getDiscountPlan(id) {
      if (this.isMounted) {
        this.activePrompt = true
        this.showLoading = true
        UserService.getDiscountPlan({ slab_id: id }).then(
          response => {
            this.discountPlan = response.discount_plan
            this.showLoading = false
          },
          error => {
            this.showLoading = false
          },
        )
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
